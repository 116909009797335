import PropTypes from 'prop-types';

import React from 'react';
import ReactSelect from 'react-select';
import Label from '@clientComponents/surfaces/Label';
import settings from '@clientSettings';
import { control, selectContainer } from './styles';

const Select = ({onChange, value, label, placeholder, labelBefore, options, width, isMulti, isDisabled, isClearable}) => (
	<div css={selectContainer({labelBefore})} >
		{label && 
			<Label htmlFor={label.toLowerCase()} label={label} />
		}
		<div css={{width}}>
			<ReactSelect
				value={value}
				onChange={(inputValue, action) => onChange(inputValue, action)}
				options={options}
				isMulti={isMulti}
				isDisabled={isDisabled}
				isClearable={isClearable}
				placeholder={placeholder}
				tabIndex={0}
				styles={{
					control: (provided, state) => control(provided, state),
					menu: base => ({ ...base, fontSize: settings.sizes.medium, zIndex: 9999 }),
					dropdownIndicator: provided => ({...provided,  padding: '2px'}),
					input: provided => ({
						...provided,
						padding: 0,
						margin: 0
					})
				}}
				width={300}
			/>
		</div>
	</div>
);

Select.propTypes = {
	onChange: PropTypes.func,
	value: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
	label: PropTypes.string,
	placeholder: PropTypes.string,
	options: PropTypes.array,
	labelBefore: PropTypes.bool,
	width: PropTypes.string,
	isMulti: PropTypes.bool,
	isDisabled: PropTypes.bool,
	isClearable: PropTypes.bool
};

Select.defaultProps = {
	onChange: () => {},
	label: '',
	placeholder: '',
	options: [],
	labelBefore: false,
	width: '300px',
	isMulti: false,
	isDisabled: false,
	isClearable: false,
	value: undefined
};

export default Select;