/* eslint-disable import/prefer-default-export */
import settings from '@clientSettings';

export const control = (provided, state) => ({
	...provided,
	minHeight: '16px',
	fontSize: settings.sizes.medium,
	borderRadius: 0,
	borderColor: state.isFocused ? `${settings.colors.primary} !important` : settings.colors.grey ,
	boxShadow: 'none !important'
});

export const indicatorContainer = (provided, state) => ({
	...provided,
	padding: '2px'
});

export const selectContainer = ({labelBefore}) => ({
	display: 'flex', 
	alignItems: labelBefore ? 'center' : 'left', 
	gap: labelBefore ?  settings.sizes.medium : '0px',  
	flexDirection: labelBefore ?  'row': 'column'
});