import spielklasseHandler from './spielklasseHandler';

const teamNameHandler = (team, teams, showClass, short) => {
	const teamCopy = {...team};
	const shortName = teamCopy.name_kurz || `${teamCopy?.name?.slice(0,4)} ...`;
	const teamName = short ? shortName : teamCopy.name;
	if (typeof team === 'object' && team?.name) {
		teamCopy.label = showClass ?`${teamName} - ${spielklasseHandler(teamCopy.spielklasse).short}`: teamName ;
	};
	
	if (teams?.length > 0 && team?.nummer) {
		const index = teams.findIndex((teamToCheck) => (`${teamToCheck?.name}-${spielklasseHandler(teamToCheck?.spielklasse).short}` === `${team.name}-${spielklasseHandler(team.spielklasse).short}`) && (teamToCheck.objectId !== team.objectId));
		if (index === -1 ) {
			teamCopy.label = showClass ? `${teamName} - ${spielklasseHandler(teamCopy.spielklasse).short}` : `${teamName}`;
		}
		if (index !== -1 ) {
			teamCopy.label = showClass ? `${teamName} ${teamCopy.nummer.toString()} - ${spielklasseHandler(teamCopy.spielklasse).short}` : `${teamName} ${teamCopy.nummer.toString()}`;
		}
	
	}
	return teamCopy;
};

export default teamNameHandler;